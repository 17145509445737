
function Footer() {

    return (
        <>
            <div className="footer_container">
                <div className="footer_wrap">
                    <div className="footer_logo_box">
                        <img src="/img/b_logo.png" alt="f_logo" />
                    </div>
                    <div className="footer_text_box">
                        <span>회사명 : (주)아쿠시</span>
                        <span>주소 : 서울 영등포구 당산로 241 유니언타운 | 연락처 : 02-2663-0435 | 이메일 : accourci@accourci.com</span>
                        <span>Copyright © ACCOURCI All rights reserved.</span>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Footer;