import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home, Intro, Solution, Recruitment, Contact, Test } from "./pages";
import Header from './Header';
import Footer from './Footer';

import Lenis from 'lenis';

// ------------ 스크롤 smooth 정의 ------------
const lenis = new Lenis({
    duration: 1.8,  // 스크롤 애니메이션 지속 시간
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),  // 사용자 정의 이징 함수
    smooth: true,   // 부드러운 스크롤 활성화
    smoothTouch: true, // 터치 기기에서 부드러운 스크롤 활성화
});

function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
}

requestAnimationFrame(raf);
// ------------ 스크롤 smooth 정의 ------------


function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<><Header /><Home /><Footer /></>} />
                    <Route path="/intro" element={<><Header /><Intro /><Footer /></>} />
                    <Route path="/solution" element={<><Header /><Solution /><Footer /></>} />
                    <Route path="/recruitment" element={<><Header /><Recruitment /><Footer /></>} />
                    <Route path="/contact" element={<><Header /><Contact /><Footer /></>} />
                    <Route path="/test" element={<><Header /><Test /><Footer /></>} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
