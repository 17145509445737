import '../css/Contact.scss';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const Contact = () => {
    return (
        <>
            <div className="contact_warp">
                <div className="contact_container">
                    <div className="contact_box flex_between">
                        <div className="ct_left_box">
                            <div className="ct_t_b_box">
                                <div className="ct_t_box">
                                    <span className='ct_tit_txt'>새로운 길을 찾고 계신가요? <br />문의하세요</span>
                                    <span className='ct_cont_txt'>07222 서울 영등포구 당산로 241 유니언타운 (당산동6가 311-1)</span>
                                    <span className='ct_cont_txt'>02-2663-0435</span>
                                    <span className='ct_cont_txt'>info@accourci.com</span>
                                </div>
                                <div className="ct_b_box">
                                    <img src="/img/m_menu_img.svg" alt="map" />
                                </div>
                            </div>

                        </div>
                        <div className="ct_right_box">
                            <div className="ct_right_1_box">
                                <div className="ct_ri_50_box display_flex">
                                    <InputGroup className="mb-3 ct_name_box">
                                        <Form.Control
                                            placeholder="이름"
                                            aria-label="이름"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            placeholder="전화번호"
                                            aria-label="전화번호"
                                            aria-describedby="basic-addon1"
                                        />
                                    </InputGroup>
                                </div>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="E-mail"
                                        aria-label="E-mail"
                                        aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="회사명"
                                        aria-label="회사명"
                                        aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <Form.Control
                                        placeholder="내용을 입력 해 주세요."
                                        as="textarea"
                                        aria-label="With textarea" />
                                </InputGroup>
                            </div>
                            <div className="terms_txt_box">
                                <span>
                                    1. 개인정보의 수집 항목<br /><br /><br />수집 항목: 이름, 이메일, 문의 내용수집 방법: 회사 홈페이지 Contact Us 페이지를 통한 문의<br />2. 개인정보의 수집 및 이용 목적<br /><br />수집 목적: 고객의 문의사항에 대한 답변 및 서비스 개선을 위한 분석 자료이용 목적: 고객 문의에 대한 답변 및 연락, 서비스 개선 및 분석 자료로 활용<br />3. 개인정보의 보유 및 이용 기간<br /><br />개인정보는 수집 시점으로부터 2년간 보유 및 이용되며, 이후에는 즉시 파기됩니다.<br />4. 동의 거부 권리 및 미동의 시 제한 사항<br /><br />본 개인정보 수집 이용에 대한 동의는 선택사항입니다. 동의 거부 시에는 Contact통한 문의를 하실 수 없습니다.<br />5. 개인정보의 파기 절차 및 방법<br /><br />개인정보 보유 기간이 경과하거나 처리 목적이 달성된 경우, 본 개인정보는 지체 없이 파기됩니다.
                                    파기 절차: 이메일 등으로 출력된 개인정보는 분쇄기로 분쇄하거나 소각 등을 통해 파기됩니다.<br />6. 개인정보 제3자 제공<br /><br />본 개인정보 수집 이용에 대해 제3자에게 제공하지 않습니다.<br />7. 개인정보 처리의 위탁<br /><br />본 개인정보는 위탁하지 않습니다.<br />8. 개인정보 주체의 권리<br /><br />이용자는 언제든지 개인정보에 대해 열람, 정정, 삭제, 처리정지 요구 등의 권있습니다.이용자는 회사의 개인정보 처리에 대해 불만을 제기할 수 있습니다.<br />9. 개인정보 관리 책임자 연락처개인정보 관리 책임자: 박세준연락처: 박세준/psj@oplasys.com
                                </span>
                            </div>
                            <div className="terms_chk_box">
                                <ul>
                                    <li>
                                        <input id="c1" type="checkbox"></input>
                                        <label htmlFor="c1">개인 정보 수집에 동의합니다.</label>
                                    </li>
                                </ul>
                            </div>
                            <div className="terms_btn_box">
                                <div className="terms_btn">
                                    <span className='f_big f_white'>보내기</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;