import '../css/Recruitment.scss';
import React, { useEffect, useState, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { motion, useScroll, useTransform, useAnimation } from "framer-motion";

gsap.registerPlugin(ScrollTrigger);

const Recruitment = () => {


    const main_text_1 = "미래를 향한 여정, ".split(" ");
    const main_text_2 = "아쿠시와 함께 걸어갈 당신을 기다립니다.".split(" ");
    const [isMainTextComplete, setMainTextComplete] = useState(false);

    const boxes = document.querySelectorAll(".recruitment_section_02_box");

    boxes.forEach(box => {
        box.addEventListener("pointermove", (e) => {
            const x = e.pageX - box.offsetLeft;
            const y = e.pageY - box.offsetTop;

            box.style.backgroundImage = `radial-gradient(circle at ${x}px ${y}px, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.9))`;
        });

        box.addEventListener("pointerleave", () => {
            box.style.backgroundImage = "none"; // 마우스가 나가면 초기화
        });
    });
    return (
        <>
            <div className="recruitment_container">
                <div className="recruitment_wrap">

                    <div className="recruitment_section_01">
                        <div className="recruitment_main_title">
                            <span className='recruitment_main_title_line'>
                                {main_text_1.map((el, i) => (
                                    <motion.div
                                        initial={{ opacity: 0, x: "-5%" }}
                                        whileInView={{ opacity: 1, x: "0%" }}
                                        className="recruitment_d_i_b"
                                        transition={{
                                            duration: 0.5,
                                            delay: i / 10,
                                        }}
                                        key={i}
                                        onAnimationComplete={() => setMainTextComplete(true)}
                                    >
                                        {el}{" "}
                                    </motion.div>
                                ))}
                            </span>
                            <span className='recruitment_main_title_line'>
                                {isMainTextComplete && (
                                    <>
                                        {main_text_2.map((el, i) => (
                                            <motion.div
                                                key={i}
                                                initial={{ opacity: 0, x: "-5%" }}
                                                whileInView={{ opacity: 1, x: "0%" }}
                                                className="recruitment_d_i_b"
                                                transition={{
                                                    duration: 0.5,
                                                    delay: i / 10,
                                                }}
                                            >
                                                {el}{" "}
                                            </motion.div>
                                        ))}
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="recruitment_section_02">
                        <div className="recruitment_section_02_wrap">
                            <div className="recruitment_section_02_box">
                                <div className="recruitment_section_02_txt_box">
                                    <p className="recruitment_section_02_tit">샛길</p>
                                    <p className="recruitment_section_02_txt">
                                        문제를 해결할 때 새로운 접근법을 모색하고, 기존의 틀을 벗어난 사고를 할 수 있는 인재를 찾습니다. 샛길을 개척하며 기존의 한계를 뛰어넘을 준비가 된 당신, 바로 우리가 찾는 인재입니다.
                                    </p>
                                </div>
                            </div>
                            <div className="recruitment_section_02_box">
                                <div className="recruitment_section_02_txt_box">

                                    <p className="recruitment_section_02_tit">지름길</p>
                                    <p className="recruitment_section_02_txt">
                                        빠르고 정확한 판단으로 가장 효과적인 방법을 찾아 문제를 해결하는 인재를 찾고 있습니다. 지름길을 발견하고 이를 통해 가치를 창출할 수 있는 당신의 역량을 보여주세요.
                                    </p>
                                </div>
                            </div>
                            <div className="recruitment_section_02_box">
                                <div className="recruitment_section_02_txt_box">
                                    <p className="recruitment_section_02_tit">오솔길</p>
                                    <p className="recruitment_section_02_txt">
                                        우리는 일상의 소소한 즐거움 속에서 행복을 찾고, 편안한 분위기에서 협력할 수 있는 인재를 기다립니다. 오솔길을 걷듯이 아늑한 마음으로 꾸준히 나아가며, 함께 성과를 만들어 갈 수 있는 당신을 환영합니다.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Recruitment;