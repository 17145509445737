import React, { useState } from 'react';

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };

    return (
        <>
            <div className="header_container">
                <div className="header_wrap">
                    <a href="/" className="header_logo_box">
                        <img src="/img/b_logo.png" alt="f_logo" />
                    </a>
                    <div className="m_header_menu_box" onClick={toggleMenu}>
                        <div className="header_menu_icon_box">
                            <div className="header_menu_icon"></div>
                        </div>
                    </div>
                    <div className="header_menu_box">
                        <div className="menu_box">
                            <div className="menu">
                                <a href="/intro" >
                                    <p>회사소개</p>
                                </a>
                            </div>
                        </div>
                        <div className="menu_box">
                            <div className="menu">
                                <a href="/solution" >
                                    <p>서비스</p>
                                </a>
                            </div>
                        </div>

                        <div className="menu_box">
                            <div className="menu">
                                <a href="/contact" >
                                    <p>문의하기</p>
                                </a>
                            </div>
                        </div>
                        {/* <div className="menu_box">
                            <div className="menu">
                                <a href="/recruitment" >
                                    <p>Careers</p>
                                </a>
                            </div>
                        </div> */}
                    </div>
                    <div className={`menu_container ${isMenuOpen ? 'open' : ''}`}>
                        <div className="m_menu_wrap">
                            <div className="close_icon" onClick={toggleMenu}>x</div>
                            <div className="m_menu_box_box">
                                <div className="m_menu_box">
                                    <div className="menu">
                                        <a href="/" >
                                            <p>홈</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="m_menu_box">
                                    <div className="menu">
                                        <a href="/intro" >
                                            <p>회사소개</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="m_menu_box">
                                    <div className="menu">
                                        <a href="/solution" >
                                            <p>서비스</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="m_menu_box">
                                    <div className="menu">
                                        <a href="/contact" >
                                            <p>문의하기</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="m_menu_img_box">
                                <img src="/img/m_menu_img.svg" alt="map" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;